import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

//import emails from '@fundrocket/common/constants/emails'
import Link from '@fundrocket/common-web/components/Link'
import Text from '@fundrocket/common-web/components/Text'

export default function Help({
  //chat,
  className,
  email,
  phone,
  prepend,
  ...props
}) {
  const linkProps = {
    weight: 'semibold',
  }

  return (
    <Text
      {...props}
      element={props.element || 'p'}
      flush={!props.element}
      className={classNames('Help', className)}>
      {prepend ? `${prepend} ` : null}
      Please{' '}
      {email && (
        <>
          email <Link {...linkProps} to={email} />
        </>
      )}
      {phone && (
        <>
          {email && ' or '}
          call <Link {...linkProps} to={phone} />
        </>
      )}
      {(prepend || (email && phone)) && '.'}
      {/*prepend && !chat && '.'*/}
      {/*chat && ' or chat us.'*/}
    </Text>
  )

  //return (
  //  <Text {...props} element={props.element || 'p'} flush={!props.element} className={classNames('Help', className)}>
  //    {prepend ? `${prepend} ` : null}
  //    Please email <Link {...linkProps} to={email} />{prepend && !chat && '.'}
  //    {/*chat && ' or chat us.'*/}
  //  </Text>
  //)
}

Help.propTypes = {
  //chat: PropTypes.bool,
  className: PropTypes.string,
  email: PropTypes.string,
  phone: PropTypes.string,
  prepend: PropTypes.node,
}

Help.defaultProps = {
  //chat: true,
  //email: emails.SUPPORT,
}
