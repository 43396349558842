import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import LinkBase from '@fundrocket/common-web/components/LinkBase'

import styles from './Link.module.scss'

export default function Link({
  children = null,
  block = false,
  className = null,
  color = null,
  event = null,
  href = null,
  onClick = null,
  to = null,
  decorated = true,
  weight = 'normal',
  ...props
}) {
  const isButton = !href && !to
  const Component = isButton ? 'button' : LinkBase

  const componentClassNames = classNames(
    'Link',
    styles.this,
    block && styles['this---block'],
    color && styles[`this---${color}`],
    decorated && !color && styles['this---decorated'],
    weight && styles[`this---weight---${weight}`],
    className
  )

  function handleClick(e) {
    if (onClick) onClick(e)
  }

  return (
    <Component
      {...props}
      href={href}
      to={to}
      event={event}
      type={isButton ? 'button' : null}
      className={componentClassNames}
      onClick={handleClick}>
      {children}
    </Component>
  )
}

Link.propTypes = {
  activeClassName: PropTypes.string,
  block: PropTypes.bool,
  children: PropTypes.node,
  className: PropTypes.string,
  color: PropTypes.oneOf(['black', 'blue', 'grey']),
  decorated: PropTypes.bool,
  event: PropTypes.oneOfType([PropTypes.bool, PropTypes.string]),
  href: PropTypes.string,
  onClick: PropTypes.func,
  to: PropTypes.string,
  weight: PropTypes.oneOf(['bold', 'semibold', 'normal', 'inherit']),
}
