import React from 'react'

import phones from '@fundrocket/common/constants/phones'
import Help from '@fundrocket/common-web/components/Help'

import emails from 'constants/emails'

export default function HelpComponent(props) {
  return <Help {...props} email={emails.PAY_SUPPORT} phone={phones.PRODUCT_OPS_SUPPORT} />
}

HelpComponent.defaultProps = {
  size: 'small',
}
