import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Container from '@fundrocket/common-web/components/Container'
import Heading from '@fundrocket/common-web/components/Heading'
import Module1 from '@fundrocket/common-web/components/Module1'
import Row from '@fundrocket/common-web/components/Row'
import Text from '@fundrocket/common-web/components/Text'
import View from '@fundrocket/common-web/components/View'

import styles from './Utility.module.scss'
import { ReactComponent as LogoSvg } from './images/logo.svg'

export default function Utility({ children, subtext, title, ...props }) {
  return (
    <View className={classNames('Utility', styles.this)}>
      <Container size="medium">
        <Text align="center">
          <Row>
            <LogoSvg aria-label="Womply Pay" />
          </Row>
          {title && (
            <Row>
              <Heading flush level="1">
                {title}
              </Heading>
            </Row>
          )}
          <Module1 {...props} title={subtext} className={styles.content}>
            {children}
          </Module1>
        </Text>
      </Container>
    </View>
  )
}

Utility.propTypes = {
  children: PropTypes.node.isRequired,
  subtext: PropTypes.string,
  title: PropTypes.string,
}
