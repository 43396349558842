import React from 'react'

import Text from '@fundrocket/common-web/components/Text'

import Head from 'components/Head'
import Help from 'components/Help'
import urls from 'constants/urls'
import Utility from 'templates/Utility'

export default function Forbidden() {
  return (
    <>
      <Head title="Forbidden" />
      <Utility
        subtext="Womply Pay requires an active Womply account"
        button="Sign up"
        buttonTo={urls.WOMPLY_SIGN_UP}
        className="Forbidden">
        <Text element="p">
          <Help element="span" size="medium" /> for support.
        </Text>
      </Utility>
    </>
  )
}
