import classNames from 'classnames'
import PropTypes from 'prop-types'
import React from 'react'

import Utility from '@fundrocket/common-web/components/Utility'

import Layout from 'components/Layout'

export default function UtilityTemplate({ className, ...props }) {
  return (
    <Layout className={classNames('Utility', className)}>
      <Utility {...props} />
    </Layout>
  )
}

UtilityTemplate.propTypes = {
  children: PropTypes.node.isRequired,
  className: PropTypes.string.isRequired,
}
